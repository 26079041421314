@import "./bootstrap/customizations.scss";

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

@import "./bootstrap/form-validation-extensions.scss";
@import "./bootstrap/bootstrap-social.scss";

@import "_variables.scss";
@import "_multiselect.scss";
@import "_forms.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Roboto', sans-serif, serif;

    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 2rem 0;
        background-color: #f5f5f5;

        @include media-breakpoint-down(sm) {
            font-size: 11px;
        }
    }

    .body-content {
        padding-bottom: 85px;
    }
}


.main-footer {
    width: 100%;
    padding: 2.125rem 0;
    background-color: #222222;
    color: white;

    .footer-text {
        font-size: 1rem;
        line-height: 1.125rem;
        color: #BCBCBE;
    }

    .footer-last-text {
        font-size: 0.875rem;
    }

    .footer-title {
        font-size: 1.125rem;
    }

    .text-reserved {
        justify-content: center;
        margin-top: 1rem;
        line-height: 1rem;
        font-size: 0.875rem;
        color: #BCBCBE;
        text-align: center;
    }
}

//Headers
h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: 3rem;
        margin-bottom: 1rem;

        &.card-title {
            margin-top: 2rem;
        }
    }
}

h2 {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.navbar-dark {
    background-color: #41218a;

    .navbar-brand img {
        display: block;
        width: 4.5rem;
    }

    .navbar-nav {
        .dropdown .dropdown-toggle {
            outline: none;
        }
    }
}

.modal-content {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.logo-footer {
    background: url('~@/assets/images/logo-2.png') no-repeat;
    background-size: 7.4375rem 7.0625rem;
    display: block;
    width: 7.4375rem;
    height: 7.0625rem;
}

.auth-wrap {
    margin: 0 auto;
    max-width: 370px;

    .logo {
        display: block;
        margin: 3rem auto;
        width: 5rem;
    }

    .card-header {
        text-align: center;
        background: none;
        border-bottom: none;
        padding: 1rem 0 0 0;
    }

    .btn-social {
        border-color: transparent;
    }

    .bottom-text {
        text-align: center;
        margin-top: 2rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: 5rem;
        margin-bottom: 5rem;

        .card-body {
            padding: 30px;

            .buttons {
                margin: 2rem auto 0 auto;
                width: 230px;

                .btn-block {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.or {
    display: block;
    position: relative;
    border-top: 1px solid #dfdfdf;
    margin: 30px 0;

    &:before {
        display: inline-block;
        content: "OR";
        color: #666666;
        background-color: #ffffff;
        position: absolute;
        text-align: center;
        top: -13px;
        width: 40px;
        left: 50%;
        margin-left: -1.25rem;
    }
}

.page-header {
    margin: 2rem 0;

    h1 {
        margin: 0 0 1rem 0;
    }

    @include media-breakpoint-up(sm) {
        margin: 3rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }
    }
}

.table {
    td {
        vertical-align: middle;
    }

    .actions-column {
        width: 100px;
    }
}

.dataTables_wrapper {
    position: relative;

    .dataTables_processing {
        z-index: 1;
    }

    .dataTable {
        border-collapse: collapse !important;

        .actions {
            width: 100px;
        }
    }

    .dataTables_info {
        @include media-breakpoint-up(sm) {
            text-align: left !important;
        }
    }

    .pagination {
        margin: 0 !important;
        padding-top: 0.7em;

        @include media-breakpoint-down(xs) {
            justify-content: center !important;
        }
    }
}

.validation-summary-errors {
    .close {
        position: relative;
        top: -2px;
        right: -21px;
        line-height: 1.125rem;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }
}

.btn-continue {
    color: #fff;
    background-color: #ffa726;
    border-color: #ffa726;
    width: 75% !important;

    @include media-breakpoint-down(sm) {
        width: 100% !important;
    }

    &:hover {
        color: #fff;
    }
}

.bg-progress {
    background-color: #ffa726 !important;
}

.title-collumn {
    display: flex;
    justify-content: flex-start;
}

.bg-grey {
    background-color: #F2F2F2;
}

.bg-contact-us {
    background-color: lightgrey;
    margin-bottom: -85px;
}

.video {
    width: 700px;
    height: 440px;

    iframe {
        width: 700px;
        height: 440px;
    }

    @include media-breakpoint-down(sm) {
        width: 345px;
        height: 245px;

        iframe {
            width: 345px;
            height: 245px;
        }
    }

    margin: 6.5rem 0 3.4375rem;
}

.container-features {
    padding-top: 1.5625rem;
    padding-bottom: 3.125rem;
}

.move-card {
    margin-top: 1.5625rem;
}

.section-title {
    text-align: center;
    font-size: 2.9375rem;
    font-weight: 700;
    line-height: 3.4375rem;
}

.feature-card {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0.0625rem 0.25rem 0.625rem 0.3125rem rgba(0, 0, 0, 0.15);
    border-radius: 0.3125rem;
    padding: 2.5rem 1.6875rem;
    text-align: center;
    height: 100%;

    .feature-title, .service-title {
        font-size: 1.25rem;
        line-height: 1.4375rem;
        font-weight: 700;
        padding: 1.5625rem 0;
    }

    .service-title {
        height: 5.3125rem;

        @include media-breakpoint-down(sm) {
            height: auto;
        }
    }

    .feature-text, .service-text {
        font-size: 1rem;
        line-height: 1.125rem;
    }

    .service-btn {
        margin-top: 1.25rem;
    }

    .service-text {
        height: 6.375rem;

        @include media-breakpoint-down(sm) {
            height: auto;
        }
    }

    .service-icon img {
        width: 100%;
    }
}

.main-title-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .main-title {
        color: #222;
        font-size: 2.275rem;
        line-height: 3.0rem;
        font-weight: 700 !important;
    }

    .main-subtitle {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.3125rem;
    }
}

.section-subtitle {
    font-size: 1.125rem;
    line-height: 1.3125rem;
    text-align: center;
}

.subscribe-btn {
    background-color: #CC0000;
    border-color: #CC0000;
    color: white;

    &:hover {
        color: white;
    }
}

.btn-red {
    background-color: #CC0000;
    border-color: #CC0000;
    color: white;
    width: 10.125rem;
    height: 3.125rem;
    margin-top: 0.625rem;

    &:hover {
        color: white;
    }

    &:not(button) {
        padding: 0.75rem 0.4rem;
    }
}

.top-container {
    @include media-breakpoint-down(sm) {
        padding-bottom: 1.5625rem;
    }
}

.navbar-dark {
    background-color: #222222 !important;

    .nav-link {
        color: white !important;

        &:hover {
            color: white !important;
        }
    }

    .btn-login {
        background: #FFFFFF;
        border-radius: 0.1875rem;
        width: 6.1875rem;
        height: 2rem;
        color: #222222 !important;
        padding: 0.4375rem 1.125rem;
        font-size: 1rem;
        line-height: 1.125rem;
        text-align: center;

        &:hover {
            color: #222222 !important;
        }
    }
}

.teacher-image {
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;

    img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.question {
    &-align {
        width: 75%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &-card {
        width: 75% !important;

        @include media-breakpoint-down(sm) {
            width: 100% !important;
        }
    }

    &-collumn {
        display: flex;
        justify-content: space-around;
    }

    &-sub {
        color: #757575;
        font-weight: 700;
        font-size: 0.875rem;
    }

    &-title {
        font-size: 0.75rem;
        color: #9e9e9e;
    }

    &-container {
        .card-body {
            padding: 0 !important;
        }

        .selected-answer {
            background-color: #fff3e0;
        }

        .custom-checkbox {
            .custom-control-input:checked ~ .custom-control-label::before {
                border-color: #ffa726 !important;
                background-color: #ffa726 !important;
            }
        }

        .custom-radio {
            .custom-control-input:checked ~ .custom-control-label::before {
                border-color: #ffa726 !important;
                background-color: transparent !important;
            }

            .custom-control-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffa726'/%3E%3C/svg%3E") !important;
                background-size: contain;
            }
        }

        .list-group-item {
            padding: 1rem 2.25rem !important;
        }

        .custom-control-label {
            font-size: 16px !important;

            &:before, &:after {
                width: 1.5rem !important;
                height: 1.5rem !important;
                left: -2rem !important;
                top: 0 !important;
            }
        }
    }
}

.custom-input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
}

.btn.disabled, .btn:disabled {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #8b8b8b;
    opacity: 1;
}

table {
    &.analytic-table {
        font-size: 12px;
        text-align: center;

        tbody {
            tr {
                td:first-child {
                    text-align: left;
                }
            }
        }
    }
}

@media print {
    .btn, footer {
        display: none;
    }

    section:nth-child(even) {
        page-break-after: always;
    }

    table {
        &.analytic-table {
            thead {
                font-size: 10px;
            }
        }
    }
}
