﻿.validation-summary-errors {
    @extend .alert, .alert-danger;

    > p:last-child{
        margin-bottom: 0;
    }
}

// Applies form controls' original validation styling depending on form-group state (`.is-invalid` and `.is-valid` class)
// The styling is copied from https://github.com/twbs/bootstrap/blob/v4.5.3/scss/mixins/_forms.scss
@mixin form-group-validation-state($state, $color, $icon) {

    .form-group {
        &.is-#{$state} {

            .#{$state}-feedback,
            .#{$state}-tooltip {
                display: block;
            }

            .form-control {
                border-color: $color;

                @if $enable-validation-icons {
                    padding-right: $input-height-inner;
                    background-image: escape-svg($icon);
                    background-repeat: no-repeat;
                    background-position: right $input-height-inner-quarter center;
                    background-size: $input-height-inner-half $input-height-inner-half;
                }

                &:focus {
                    border-color: $color;
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
            }

            textarea.form-control {
                @if $enable-validation-icons {
                    padding-right: $input-height-inner;
                    background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
                }
            }

            .custom-select {
                border-color: $color;

                @if $enable-validation-icons {
                    padding-right: $custom-select-feedback-icon-padding-right;
                    background: $custom-select-background, escape-svg($icon) $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
                }

                &:focus {
                    border-color: $color;
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
            }

            .form-check-input {
                ~ .form-check-label {
                    color: $color;
                }

                ~ .#{$state}-feedback,
                ~ .#{$state}-tooltip {
                    display: block;
                }
            }

            .custom-control-input {
                ~ .custom-control-label {
                    color: $color;

                    &::before {
                        border-color: $color;
                    }
                }

                &:checked {
                    ~ .custom-control-label::before {
                        border-color: lighten($color, 10%);
                        @include gradient-bg(lighten($color, 10%));
                    }
                }

                &:focus {
                    ~ .custom-control-label::before {
                        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                    }

                    &:not(:checked) ~ .custom-control-label::before {
                        border-color: $color;
                    }
                }
            }
            // custom file
            .custom-file-input {
                ~ .custom-file-label {
                    border-color: $color;
                }

                &:focus {
                    ~ .custom-file-label {
                        border-color: $color;
                        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                    }
                }
            }
        }
    }
}

// Form group validation
//
// Provide feedback to users when form field values are valid or invalid.

@each $state, $data in $form-validation-states {
    @include form-group-validation-state($state, map-get($data, color), map-get($data, icon));
}